<template>
  <Container
    :preloader="preloader"
    class="container"
  >
    <template #content>
      <form @submit.prevent="submit()">
        <h2>
          {{ $t('views.auth.sign-up-phone-verify.title') }}
        </h2>

        <p>
          {{ $t('views.auth.sign-up-phone-verify-resend.text') }}
        </p>

        <BlueButton
          :text="$t(buttonText)"
          :loading="loading"
          type="submit"
          :disabled="loading || seconds > 0"
        />

        <BlueText
          tag="RouterLink"
          :text="$t('views.auth.sign-up-phone-verify-resend.back')"
          :to="{ name: 'SignUpVerifyPhoneCode' }"
        />
      </form>
    </template>
  </Container>
</template>

<script setup>
import { ref, computed, onMounted, getCurrentInstance } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import Container from '@/ui/preloader/ItemPreloaderContainer.vue'
import BlueButton from '@/ui/buttons/BlueButton.vue'
import BlueText from '@/ui/buttons/BlueText.vue'

const app = getCurrentInstance()
const { appContext: { config: { globalProperties: { $axios } } } } = app
const $route = useRoute()
const $router = useRouter()

const preloader = ref(true)
const loading = ref(false)

const seconds = ref(0)
const timer = ref(null)

const buttonText = computed(() => (
  seconds.value > 0 ? `${seconds.value}s.` : 'views.auth.sign-up-phone-verify-resend.button'
))

async function fetch () {
  try {
    preloader.value = true

    await $axios.get(`auth/get-phone-by-hash/${$route.params.hash}`)
  } catch (err) {
    $router.push({ name: 'SignIn' })
    throw new Error(err)
  } finally {
    preloader.value = false
  }
}

async function submit () {
  try {
    loading.value = true

    await $axios.post(`auth/sens-sms-by-hash/${$route.params.hash}`)
  } catch (err) {
    throw new Error(err)
  } finally {
    createTimer()
    loading.value = false
  }
}

function createTimer () {
  seconds.value = 60

  timer.value = setInterval(() => {
    if (seconds.value < 1) {
      seconds.value = 0
      clearInterval(timer.value)
    } else {
      seconds.value = seconds.value - 1
    }
  }, 1000)
}

onMounted(() => {
  fetch()
})
</script>

<style lang="scss" scoped>
.container {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    h2 {
      font-size: 32px;
      line-height: 100%;
      color: #202539;
      font-family: SemiBold;
    }

    p {
      color: rgba(156, 163, 184, 1);
      font-family: Medium;
      font-size: 14px;
      line-height: 120%;
    }
  }
}
</style>
