<template>
  <component
    :is="tag"
    class="button-text"
    @click.stop="$emit('onclick')"
  >
    {{ text }}
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: 'button'
    },
    text: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
button, a {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  color: rgba(47, 87, 233, 1);
  font-family: SemiBold;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  transition: .2s;

  &:hover {
    color: rgb(87, 127, 255);
  }

  &:active {
    color: rgba(32, 37, 57);
  }

  &:disabled {
    color: rgba(156, 163, 184, 1);
    cursor: not-allowed;
  }
}
</style>
